export default {
    data() {
        return {
            options: {},
        }
    },
    methods: {
        loadOptions(opts={}, field='options'){
            return new Promise((resolve, reject) => {
                if (this.$store.isAdmin){
                    axios.post(this.route('admin.options'), opts).then( response => {
                        this[field] = response.data;
                        resolve(this[field])
                    }).catch( err => {
                        reject(err);
                    })
                } else {
                    axios.post(this.route('user.options'), opts).then( response => {
                        this[field] = response.data;
                        resolve(this[field])
                    }).catch( err => {
                        reject(err);
                    })
                }
            });
        },
    }
}
