import hasOptions from "./hasOptions";

export default {
    mixins: [hasOptions],
    data(){
        return {
            paginationParams: {
                page: 1,
                per_page: 5,
                sort_by: [],
                sort_desc: [],
            },
            footerProps:{
                itemsPerPageOptions: [10,20,30,50,100],
            },
            url: '',
            loading: false,
        }
    },
    created() {
        if(this.url){
            this.initialize();
        }
    },
    methods: {
        changePage(page){
            if (!this.loading){
                this.paginationParams.page = page;
                this.initialize();
            }
        },
        changeItemsPerPage(itemsPerPage){
            this.paginationParams.per_page = itemsPerPage;
            this.initialize();
        },
        sortBy(fields){
            this.paginationParams.sort_by = fields;
        },
        sortDesc(fields){
            this.paginationParams.sort_desc = fields;
            this.initialize();
        },
        initialize(){
            this.loading = true;
            let params = this.paginationParams;
            _.each(this.filters, (item, key) => {
                params[key] = item;
            });
            axios.get(this.url, {params: params}).then( response => {
                this.paginationItems = response.data;
                this.paginationParams.page = response.data.current_page;
            }).catch( () => {

            }).then( () => {
                this.loading = false;
            })
        }
    },
    computed: {
        // paginationItems(){
        //     return this.users;
        // },
        pagination(){
            if (!this.paginationItems) return null;
            return {
                page: this.paginationItems.current_page,
                itemsPerPage: this.paginationItems.per_page,
                pageStart: 1,
                pageStop: this.paginationItems.last_page,
                pageCount: Math.ceil(this.paginationItems.total / this.paginationItems.per_page),
                itemsLength: this.paginationItems.total,
            }
        }
    }

}
